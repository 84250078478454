import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { Toast } from 'primereact/toast';
import { handleAxiosError } from '../../../helpers/helpers';

interface ChangeRequestStatusProps {
    notification: React.RefObject<Toast>
    requestId: string,
    statusId: number
}

export const changeRequestStatus = async ({ notification, requestId, statusId }: ChangeRequestStatusProps) => {
    try {
        let urlAction = '';
        switch (statusId) {
            case 4:
                urlAction = 'done';
                break;
            case 5:
                urlAction = 'cancel';
                break;
            case 6:
                urlAction = 'cannotdo';
                break;

            default:
                throw new Error('invalid status');
        }
        const url = `/user/balance/requests/${urlAction}/${requestId}`;
        const res = await axiosInstance.put(url);

        if (notification.current) {
            notification.current.show({
                severity: 'success',
                summary: i18n.t('user.success.changeRequestStatus.summary'),
            });
        }
        return res;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('user.errors.changeRequestStatus.summary'));

        throw error;
    }
};