import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { Toast } from 'primereact/toast';
import { handleAxiosError } from '../../../helpers/helpers';
import { ChangeBalanceBreakdownParams } from './types';

export interface ChangeBalanceBreakdownProps {
    notification: React.RefObject<Toast>
    balanceBreakdownId: string,
    params: ChangeBalanceBreakdownParams
}

export const changeBalanceBreakdown = async ({ notification, balanceBreakdownId, params }: ChangeBalanceBreakdownProps) => {
    try {
        const res = await axiosInstance.put(`/user/balance/breakdown/${balanceBreakdownId}`, params);

        if (notification.current) {
            notification.current.show({
                severity: 'success',
                summary: i18n.t('user.success.changeBalanceBreakdown.summary'),
            });
        }
        return res;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('user.errors.changeBalanceBreakdown.summary'));

        throw error;
    }
};