import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { Toast } from 'primereact/toast';
import { handleAxiosError } from '../../../helpers/helpers';
import { ChangePasswordRequestParams } from './types';

interface ChangePasswordProps {
    notification: React.RefObject<Toast>
    params: ChangePasswordRequestParams
}

export const changePassword = async ({ notification, params }: ChangePasswordProps) => {
    try {
        const res = await axiosInstance.put(`/user/change-password-web`, params);

        if (notification.current) {
            notification.current.show({
                severity: 'success',
                summary: i18n.t('myProfile.success.changePassword.summary'),
            });
        }
        return res;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('myProfile.errors.changePassword.summary'));

        throw error;
    }
};