import { Toast } from "primereact/toast";
import axiosInstance from '../../axios-config';
import i18n from "../../..";
import { handleAxiosError } from "../../../helpers/helpers";

interface CloseUserProps {
    notification: React.RefObject<Toast>,
    userId: string
}

export const closeUser = async ({ notification, userId }: CloseUserProps) => {
    try {
        const res = await axiosInstance.put(`/users/close/${userId}`);

        if (notification.current) {
            notification.current.show({
                severity: 'success',
                summary: i18n.t('users.success.closeUser.summary'),
            });
        }
        return res;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('users.errors.closeUser.summary'));

        throw error;
    }
};