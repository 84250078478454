import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { AxiosResponse } from 'axios';
import { handleAxiosError } from '../../../helpers/helpers';

interface ExportUsersProps {
    notification: React.RefObject<Toast>
}

export const exportUsers = async ({ notification }: ExportUsersProps) => {
    try {
        const res: AxiosResponse<Blob> = await axiosInstance.get('/users/export', { responseType: 'blob' });
        return res;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('users.errors.exportUsers.summary'));

        throw error;
    }
};