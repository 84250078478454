import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { getUserRequests } from "../../../../api/requests/getUserRequests/GetUserRequests";
import { UserRequest } from "../../../../api/requests/getUserRequests/types";
import { DropdownAction, TableColumn } from './types';
import { formatDateFunc, formatDollar, formatPercent } from "../../../../helpers/helpers";
import { ChangeStatus } from "./modals/ChangeStatus";
import { ChangeFee } from "./modals/ChangeFee";

interface RequestsProps {
    notification: React.RefObject<Toast>,
    fetchUserBalance: () => void,
}

export const Requests = ({ notification, fetchUserBalance }: RequestsProps) => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const requestsPerPage = 5;
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalRequests, setTotalRequests] = useState<number>();
    const [changeStatusModalVisible, setChangeStatusModalVisible] = useState<boolean>(false);
    const [changeFeeModalVisible, setChangeFeeModalVisible] = useState<boolean>(false);
    const [selectedRequest, setSelectedRequest] = useState<UserRequest | null>(null);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!changeStatusModalVisible) {
            setSelectedRequest(null);
        }
    }, [changeStatusModalVisible]);

    useEffect(() => {
        if (!changeFeeModalVisible) {
            setSelectedRequest(null);
        }
    }, [changeFeeModalVisible]);

    const fetchUsersRequests = async () => {
        try {
            const params = {
                notification,
                userId,
                params: {
                    size: requestsPerPage,
                    page: pageNumber,
                    sort: 'date,desc'
                }
            };
            const res = await getUserRequests(params);
            setTotalRequests(res.count);

            return res.data;
        } catch (error) {
            console.warn(error);
            return [];
        }
    };

    const { data, isLoading, refetch } = useQuery(['usersRequests', pageNumber], fetchUsersRequests);

    // Refetch users balance, requests, and balance breakdowns after status update:
    const refetchAll = () => {
        refetch();
        fetchUserBalance();
        queryClient.invalidateQueries('usersBalanceBreakdowns');
    };

    const getStatus = (userRequest: UserRequest) => {
        return (
            <div>
                {t(`user.requestsTable.status.${userRequest.status}`)}
            </div>
        );
    };

    const getType = (userRequest: UserRequest) => {
        return (
            <div>
                {t(`user.requestsTable.type.${userRequest.type}`)}
            </div>
        );
    };

    const formatDate = (userRequest: UserRequest) => {
        return (
            <div>
                {formatDateFunc(userRequest.date)}
            </div>
        );
    };

    const getAmount = (userRequest: UserRequest) => {
        return (
            <div>
                {formatDollar(userRequest.amount)}
            </div>
        );
    };

    const getGasFee = (userRequest: UserRequest) => {
        return (
            <div>
                {formatPercent(userRequest.gasFee)}
            </div>
        );
    };

    const getScFee = (userRequest: UserRequest) => {
        return (
            <div>
                {formatPercent(userRequest.scFee)}
            </div>
        );
    };

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setPageNumber(event.page);
    };

    const tableData: TableColumn[] = [
        {
            field: "type",
            header: t('user.requestsTable.typeTitle'),
            body: getType
        },
        {
            field: "amount",
            header: t('user.requestsTable.amount'),
            body: getAmount
        },
        {
            field: "gasFee",
            header: t('user.requestsTable.gasFee'),
            body: getGasFee
        },
        {
            field: "scFee",
            header: t('user.requestsTable.scFee'),
            body: getScFee
        },
        {
            field: "walletAddress",
            header: t('user.requestsTable.walletAddress')
        },
        {
            field: "status",
            header: t('user.requestsTable.statusTitle'),
            body: getStatus
        },
        {
            field: "date",
            header: t('user.requestsTable.date'),
            body: formatDate
        }
    ];

    const getActions = (rowData: UserRequest): DropdownAction[] => {
        let actions: DropdownAction[] = [
            {
                label: t('user.requestsTable.actions.changeStatus'),
                onClick: (rowData: UserRequest) => {
                    setSelectedRequest(rowData);
                    setChangeStatusModalVisible(true);
                }
            },
            {
                label: t('user.requestsTable.actions.changeFee'),
                onClick: (rowData: UserRequest) => {
                    setSelectedRequest(rowData);
                    setChangeFeeModalVisible(true);
                }
            },
        ];

        return actions;
    };

    const actionsTemplate = (rowData: UserRequest) => {
        return (
            <div>
                <Dropdown className='actions-dropdown' onChange={(event: DropdownChangeEvent) => event.value.onClick(rowData)} dropdownIcon="pi pi-ellipsis-v" optionLabel="label" options={getActions(rowData)} />
            </div>
        );
    };

    return (
        <div className="mb-5">
            <h2>{t('user.requestsTable.title')}</h2>
            {
                isLoading ? (
                    <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                ) : (
                    <div>
                        <DataTable value={data} >
                            {tableData.map((col) => (
                                <Column key={col.field} field={col.field} header={col.header} body={col.body} />
                            ))}
                            <Column
                                header={'Actions'}
                                align="left"
                                alignFrozen="right"
                                frozen
                                style={{ width: '80px', textAlign: 'right' }}
                                body={actionsTemplate}
                            />
                        </DataTable>
                        <Paginator first={pageNumber * requestsPerPage} rows={requestsPerPage} totalRecords={totalRequests} onPageChange={onPageChange} />
                    </div>
                )
            }
            <Dialog header={t('user.modals.changeRequestStatus.title')} visible={changeStatusModalVisible} onHide={() => setChangeStatusModalVisible(false)}>
                {selectedRequest && <ChangeStatus notification={notification} setVisible={setChangeStatusModalVisible} refetch={refetchAll} request={selectedRequest!} />}
            </Dialog>
            <Dialog header={t('user.modals.changeRequestFee.title')} visible={changeFeeModalVisible} onHide={() => setChangeFeeModalVisible(false)}>
                {selectedRequest && <ChangeFee notification={notification} setVisible={setChangeFeeModalVisible} refetch={refetch} request={selectedRequest!} />}
            </Dialog>
        </div>
    );
};