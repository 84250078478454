import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import { LoginRequestParams, LoginResponseData } from './types';
import i18n from '../../../index';
import { handleAxiosError } from '../../../helpers/helpers';

interface loginProps {
    params: LoginRequestParams,
    notification: React.RefObject<Toast>
}

export const login = async ({ params, notification }: loginProps) => {
    const fixedParams = {
        email: params.mail,
        password: params.password,
        isWebApp: true,
    };
    try {
        const res = await axiosInstance.post<LoginResponseData>('/user/login', fixedParams);
        return res.data;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('login.errors.summary'));

        throw error;
    }
};