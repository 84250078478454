import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { UserBalance } from "../../../../../api/requests/getUserBalance/types";
import { changeUserBalance } from "../../../../../api/requests/changeUserBalance/ChangeUserBalance";
import { ChangeUserBalanceParams } from "../../../../../api/requests/changeUserBalance/types";
import { MAX_NUMBER } from "../../../../../helpers/helpers";
import styles from './ChangeBalance.module.scss';

interface ChangeBalanceProps {
    notification: React.RefObject<Toast>,
    setVisible: Dispatch<SetStateAction<boolean>>,
    balance: UserBalance,
    refetch: () => void,
}

export const ChangeBalance = ({ notification, setVisible, balance, refetch }: ChangeBalanceProps) => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const minInvested = 0;
    const formik = useFormik<UserBalance>({
        initialValues: balance,
        validate: (data: UserBalance) => {
            let errors: any = {};

            if (data.balance === undefined || data.balance === null) {
                errors.balance = t('user.modals.changeBalance.errors.balanceMissing');
            }
            if (data.totalInvested === undefined || data.totalInvested === null) {
                errors.totalInvested = t('user.modals.changeBalance.errors.totalInvestedMissing');
            }
            if (data.growth === undefined || data.growth === null) {
                errors.growth = t('user.modals.changeBalance.errors.growthMissing');
            }
            if (data.amountEarned === undefined || data.amountEarned === null) {
                errors.amountEarned = t('user.modals.changeBalance.errors.amountEarnedMissing');
            }

            return errors;
        },
        onSubmit: async (data: UserBalance) => {
            if (userId) {
                try {
                    const newData: ChangeUserBalanceParams = {
                        amountEarned: data.amountEarned,
                        balance: data.balance,
                        growth: data.growth,
                        totalInvested: data.totalInvested,
                    };
                    const params = {
                        notification,
                        userId,
                        params: newData
                    };
                    await changeUserBalance(params);
                    refetch();
                    setVisible(false);
                } catch (error) {
                    console.warn(error);
                }
            }
        }
    });

    const isFormFieldInvalid = (name: string) => !!((formik.touched as { [key: string]: boolean })[name] && (formik.errors as { [key: string]: boolean })[name]);

    const getFormErrorMessage = (name: string) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{(formik.errors as { [key: string]: boolean })[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const handleBlur = useCallback(() => {
        if (formik.values.balance > MAX_NUMBER) {
            formik.setFieldValue('balance', MAX_NUMBER);
        }
        if (formik.values.totalInvested > MAX_NUMBER) {
            formik.setFieldValue('totalInvested', MAX_NUMBER);
        }
        if (formik.values.growth > MAX_NUMBER) {
            formik.setFieldValue('growth', MAX_NUMBER);
        }
        if (formik.values.amountEarned > MAX_NUMBER) {
            formik.setFieldValue('amountEarned', MAX_NUMBER);
        }
    }, [formik]);

    useEffect(() => {
        handleBlur();
    }, [handleBlur]);

    return (
        <div>
            <form className={styles.formWrapper} onSubmit={(event) => event.preventDefault()}>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputNumber
                            inputId="balance"
                            value={formik.values.balance}
                            onChange={(e: InputNumberChangeEvent) => formik.setFieldValue('balance', e.value)}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            max={MAX_NUMBER}
                            className={`${isFormFieldInvalid('balance') && 'p-invalid'}`}
                            onBlur={handleBlur}
                        />
                        <label htmlFor="balance">{t('user.modals.changeBalance.form.balance')}</label>
                    </span>
                    {getFormErrorMessage('balance')}
                </div>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputNumber
                            inputId="totalInvested"
                            value={formik.values.totalInvested}
                            onChange={(e: InputNumberChangeEvent) => formik.setFieldValue('totalInvested', e.value)}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            max={MAX_NUMBER}
                            min={minInvested}
                            className={`${isFormFieldInvalid('totalInvested') && 'p-invalid'}`}
                            onBlur={handleBlur}
                        />
                        <label htmlFor="totalInvested">{t('user.modals.changeBalance.form.totalInvested')}</label>
                    </span>
                    {getFormErrorMessage('totalInvested')}
                </div>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputNumber
                            inputId="growth"
                            value={formik.values.growth}
                            onChange={(e: InputNumberChangeEvent) => formik.setFieldValue('growth', e.value)}
                            prefix="%"
                            maxFractionDigits={2}
                            max={MAX_NUMBER}
                            className={`${isFormFieldInvalid('growth') && 'p-invalid'}`}
                            onBlur={handleBlur}
                        />
                        <label htmlFor="growth">{t('user.modals.changeBalance.form.growth')}</label>
                    </span>
                    {getFormErrorMessage('growth')}
                </div>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputNumber
                            inputId="amountEarned"
                            value={formik.values.amountEarned}
                            onChange={(e: InputNumberChangeEvent) => formik.setFieldValue('amountEarned', e.value)}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            max={MAX_NUMBER}
                            className={`${isFormFieldInvalid('amountEarned') && 'p-invalid'}`}
                            onBlur={handleBlur}
                        />
                        <label htmlFor="amountEarned">{t('user.modals.changeBalance.form.amountEarned')}</label>
                    </span>
                    {getFormErrorMessage('amountEarned')}
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button label={t('user.modals.cancel')} onClick={() => setVisible(false)} text />
                    <Button onClick={formik.submitForm} label={t('user.modals.save')} />
                </div>
            </form>
        </div>
    );
};