import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { UserInfo } from "../../api/requests/getUserInfo/types";
import { getUserInfo } from "../../api/requests/getUserInfo/GetUserInfo";
import { ChangePassword } from "./modals/ChangePassword";
import styles from './Profile.module.scss';

interface ProfileProps {
    notification: React.RefObject<Toast>
}

export const Profile = ({ notification }: ProfileProps) => {
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState<boolean>(false);

    const fetchUserInfo = useCallback(async () => {
        try {
            const res = await getUserInfo({ notification });
            setUserInfo(res);
        } catch (error) {
            console.warn(error);
        }
    }, [notification]);

    useEffect(() => {
        fetchUserInfo();
    }, [fetchUserInfo]);

    const handleModalClose = useCallback(() => {
        setChangePasswordModalVisible(false);
    }, []);

    return (
        <div>
            <h1>{t('myProfile.title')}</h1>
            <div className={styles.outerContentWrapper}>
                <div className={styles.contentWrapper}>
                    <div className={styles.pair}>
                        <h3>{t('myProfile.user')}</h3>
                        {userInfo ? <h2>{userInfo.name}</h2> : t('myProfile.loading')}
                    </div>
                    <div className={styles.pair}>
                        <h3>{t('myProfile.mail')}</h3>
                        {userInfo ? <h2>{userInfo.mail}</h2> : t('myProfile.loading')}
                    </div>
                    <Button onClick={() => setChangePasswordModalVisible(true)} className="mt-3" label={t('myProfile.changePassword')} />
                </div>
            </div>
            <Dialog header={t('myProfile.modals.changePassword.title')} visible={changePasswordModalVisible} onHide={handleModalClose}>
                <ChangePassword notification={notification} setVisible={setChangePasswordModalVisible} userInfo={userInfo} />
            </Dialog>
        </div>
    );
};