import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { UserRequest } from "../../../../../api/requests/getUserRequests/types";
import { changeRequestStatus } from "../../../../../api/requests/changeRequestStatus/ChangeRequestStatus";
import styles from './ChangeStatus.module.scss';

interface ChangeStatusProps {
    notification: React.RefObject<Toast>,
    setVisible: Dispatch<SetStateAction<boolean>>,
    request: UserRequest,
    refetch: () => void,
}

interface RequestStatus {
    id: number,
    label: string,
}

export const ChangeStatus = ({ notification, setVisible, request, refetch }: ChangeStatusProps) => {
    const { t } = useTranslation();
    const [selectedStatus, setSelectedStatus] = useState<RequestStatus>({
        id: request.status,
        label: t(`user.requestsTable.status.${request.status}`)
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const submitForm = async () => {
        try {
            setIsLoading(true);
            const params = {
                notification,
                requestId: request.id,
                statusId: selectedStatus.id,
            };
            await changeRequestStatus(params);
            refetch();
            setVisible(false);
        } catch (error) {
            console.warn(error);
        } finally {
            setIsLoading(false);
        }
    };
    const options: RequestStatus[] = [
        {
            id: 4,
            label: t('user.requestsTable.status.4')
        },
        {
            id: 5,
            label: t('user.requestsTable.status.5')
        },
        {
            id: 6,
            label: t('user.requestsTable.status.6')
        },
    ];

    return (
        <form className={styles.formWrapper} onSubmit={(event) => event.preventDefault()}>
            <div className="mb-5">
                <span className="p-float-label">
                    <Dropdown inputId="requestStatus" value={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} options={options} optionLabel="label" className="w-20rem" />
                    <label htmlFor="requestStatus">{t('user.modals.changeRequestStatus.statusTitle')}</label>
                </span>
            </div>
            <div className={styles.buttonsWrapper}>
                <Button label={t('user.modals.cancel')} onClick={() => setVisible(false)} text />
                <Button loading={isLoading} onClick={submitForm} disabled={selectedStatus.id === request.status} label={t('user.modals.save')} />
            </div>
        </form>
    );
};