import { Dispatch, FormEvent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Tooltip } from "primereact/tooltip";
import { UserInfo } from "../../../api/requests/getUserInfo/types";
import { ChangePasswordInterface, ChangePasswordRequestParams } from "../../../api/requests/changePassword/types";
import styles from './ChangePassword.module.scss';
import { changePassword } from "../../../api/requests/changePassword/ChangePassword";
import { useNavigate } from "react-router-dom";

interface ChangePasswordProps {
    notification: React.RefObject<Toast>,
    setVisible: Dispatch<SetStateAction<boolean>>,
    userInfo?: UserInfo,
}

export const ChangePassword = ({ notification, setVisible, userInfo }: ChangePasswordProps) => {
    const { t } = useTranslation();
    const passwordPolicies = () => {
        return new RegExp(`^(?!.*\\b(?:password|username|${userInfo?.mail}|${userInfo?.name})\\b)(?!.*(.)\\1)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?/~\`\\-=/\\|";])[\\S]{10,}$`);
    };
    const navigate = useNavigate();

    const { values, touched, errors, submitForm, setFieldValue } = useFormik<ChangePasswordInterface>({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: ''
        },
        validate: (values: ChangePasswordInterface) => {
            let errors: any = {};

            if (!values.oldPassword) {
                errors.oldPassword = t('myProfile.modals.changePassword.errors.oldPasswordMissing');
            }
            if (!values.newPassword) {
                errors.newPassword = t('myProfile.modals.changePassword.errors.newPasswordMissing');
            }
            if (values.newPassword && !passwordPolicies().test(values.newPassword)) {
                errors.newPassword = t('myProfile.modals.changePassword.errors.newPasswordNotByCriteria');
            }
            if (!values.repeatNewPassword) {
                errors.repeatNewPassword = t('myProfile.modals.changePassword.errors.repeatNewPasswordMissing');
            }
            if (values.newPassword && values.repeatNewPassword && values.newPassword !== values.repeatNewPassword) {
                errors.repeatNewPassword = t('myProfile.modals.changePassword.errors.repeatNewPasswordMissmatch');
            }

            return errors;
        },
        onSubmit: async (values: ChangePasswordInterface) => {
            if (userInfo) {
                try {
                    const params: ChangePasswordRequestParams = {
                        oldPassword: values.oldPassword,
                        newPassword: values.newPassword,
                        mail: userInfo.mail
                    };
                    await changePassword({ notification, params });
                    localStorage.removeItem('smartCube_access_token');
                    localStorage.removeItem('smartCube_refresh_token');
                    navigate('/login');
                    setVisible(false);
                } catch (error) {
                    console.warn(error);
                }
            }
        },
    });

    const isFormFieldInvalid = (name: string) => !!((touched as { [key: string]: boolean })[name] && (errors as { [key: string]: boolean })[name]);

    const getFormErrorMessage = (name: string) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{(errors as { [key: string]: boolean })[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const submitFormFunc = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submitForm();
    };

    return (
        <form className={styles.formWrapper} onSubmit={(event: FormEvent<HTMLFormElement>) => submitFormFunc(event)}>
            <div className="mb-5">
                <span className="p-float-label">
                    <Password
                        id="oldPassword"
                        value={values.oldPassword}
                        onChange={(e) => setFieldValue('oldPassword', e.target.value)}
                        toggleMask
                        feedback={false}
                    />
                    <label htmlFor="oldPassword">{t('myProfile.modals.changePassword.oldPassword')}</label>
                </span>
                {getFormErrorMessage('oldPassword')}
            </div>
            <div className={`mb-5 ${styles.newPasswordWrapper}`}>
                <div className="w-full">
                    <span className="p-float-label">
                        <Password
                            id="newPassword"
                            value={values.newPassword}
                            onChange={(e) => setFieldValue('newPassword', e.target.value)}
                            className="w-full"
                            toggleMask
                            feedback={false}
                        />
                        <label htmlFor="newPassword">{t('myProfile.modals.changePassword.newPassword')}</label>
                    </span>
                    {getFormErrorMessage('newPassword')}
                </div>
                <i className="pi pi-info-circle passwordCriteriaTooltip ml-2"></i>
                <Tooltip
                    target=".passwordCriteriaTooltip"
                    position='bottom'
                >
                    {t('myProfile.modals.changePassword.passwordCriteria')}
                </Tooltip>
            </div>
            <div className="mb-5">
                <span className="p-float-label">
                    <Password
                        id="repeatNewPassword"
                        value={values.repeatNewPassword}
                        onChange={(e) => setFieldValue('repeatNewPassword', e.target.value)}
                        toggleMask
                        feedback={false}
                    />
                    <label htmlFor="repeatNewPassword">{t('myProfile.modals.changePassword.repeatNewPassword')}</label>
                </span>
                {getFormErrorMessage('repeatNewPassword')}
            </div>
            <div className={styles.buttonsWrapper}>
                <Button type="button" label={t('myProfile.modals.cancel')} onClick={() => setVisible(false)} text />
                <Button type="submit" label={t('myProfile.modals.save')} />
            </div>
        </form>
    );
};