import jwt_decode from "jwt-decode";
import { UserToken } from "./types";
import { Toast } from "primereact/toast";

export const isTokenValid = (token: string | null): boolean => {
    if (token === null) {
        return false;
    }
    try {
        const decodedAccessToken: UserToken = jwt_decode(`${token}`);
        const expirationDate = new Date(decodedAccessToken.exp * 1000);
        const currentDate = new Date();

        if (expirationDate > currentDate) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const handleAxiosError = (error: Error | any, notification: React.RefObject<Toast>, summary: string) => {
    let errorMessage = error.message;
    if (error?.response?.data.messages)
        errorMessage = error?.response?.data.messages[0];
    if (error?.response?.data.message)
        errorMessage = error?.response?.data.message;

    if (notification.current) {
        notification.current.show({
            severity: 'error',
            summary: summary,
            detail: errorMessage,
        });
    }

    if (error.response && error.response.status === 401) {
        // window.location.href = '/login';
        console.log('auth problem, redirect to login');
    }
};

export const formatDateFunc = (date: Date | string) => {
    const dateObject = new Date(date);
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();

    return `${day}.${month}.${year}`;
};

export const formatDollar = (amount: number): string => {
    const formattedAmount = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formattedAmount;
};

export const formatPercent = (percentage: number): string => {
    const adjustedPercentage = percentage / 100;

    const formattedAmount = adjustedPercentage.toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 2
    });

    return formattedAmount;
};

export function formatDateToYYYYMMDD(date: Date) {
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);
    return `${newDate.toISOString().slice(0, 10)}`;
}

export const MAX_NUMBER = 10000000;