import axios, { InternalAxiosRequestConfig } from 'axios';
import { apiVersion } from '../appVersion';
import { isTokenValid } from '../helpers/helpers';
import { refreshToken } from './requests/refreshToken/RefreshToken';
import globalRouter from '../globalRouter';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}${apiVersion}`,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

type QueueRequest = {
    config: InternalAxiosRequestConfig<any>,
    resolve: (value: InternalAxiosRequestConfig<any> | PromiseLike<InternalAxiosRequestConfig<any>>) => void,
    reject: (reason?: any) => void
}
let tokenRefreshQueue: QueueRequest[] = [];

const addToTokenRefreshQueue = async (config: InternalAxiosRequestConfig<any>, smartCubeRefreshToken: string): Promise<InternalAxiosRequestConfig<any>> => {
    return new Promise(async (resolve, reject) => {
        tokenRefreshQueue.push({ config, resolve, reject });

        if (tokenRefreshQueue.length === 1) {
            try {
                const res = await refreshToken({ refreshTokenValue: smartCubeRefreshToken });
                if (res) {
                    localStorage.setItem('smartCube_access_token', res.access_token);
                    localStorage.setItem('smartCube_refresh_token', res.refresh_token);
                    const newAccessToken = `Bearer ${res.access_token}`;
                    tokenRefreshQueue.forEach((queuedRequest) => {
                        queuedRequest.config.headers.Authorization = newAccessToken;
                        queuedRequest.resolve(queuedRequest.config);
                    });

                    tokenRefreshQueue = [];
                } else {
                    reject("Token refresh failed");
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

instance.interceptors.request.use(
    async (config) => {
        const smartCubeAccessToken = localStorage.getItem('smartCube_access_token');
        const smartCubeRefreshToken = localStorage.getItem('smartCube_refresh_token');

        if (isTokenValid(smartCubeAccessToken)) {
            config.headers.Authorization = `Bearer ${smartCubeAccessToken}`;
        } else if (isTokenValid(smartCubeRefreshToken)) {
            return addToTokenRefreshQueue(config, smartCubeRefreshToken!);
        } else if (window.location.pathname !== '/login') {
            localStorage.removeItem('smartCube_access_token');
            localStorage.removeItem('smartCube_refresh_token');
            globalRouter.navigate!("/login");
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;