import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { handleAxiosError } from '../../../helpers/helpers';
import { SystemSettings } from '../getSystemSettings/types';

interface ChangeSystemSettingsProps {
    notification: React.RefObject<Toast>,
    params: SystemSettings,
}

export const changeSystemSettings = async ({ notification, params }: ChangeSystemSettingsProps) => {
    try {
        const res = await axiosInstance.put(`/system-settings`, params);

        if (notification.current) {
            notification.current.show({
                severity: 'success',
                summary: i18n.t('systemSettings.success.changeSystemSettings.summary'),
            });
        }
        return res.data;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('systemSettings.errors.changeSystemSettings.summary'));

        throw error;
    }
};