import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { handleAxiosError } from '../../../helpers/helpers';
import { SystemSettings } from './types';

interface GetSystemSettingsProps {
    notification: React.RefObject<Toast>
}

export const getSystemSettings = async ({ notification }: GetSystemSettingsProps) => {
    try {
        const res = await axiosInstance.get<SystemSettings>(`/system-settings`);
        return res.data;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('systemSettings.errors.getSystemSettings.summary'));

        throw error;
    }
};