export interface Investor {
    id: string;
    name: string;
    username: string;
    phoneNumber: string;
    balance: number;
    status: Status;
    openRequest: OpenRequest;
}

export enum Status {
    CLOSED = 0,
    ACTIVE = 1,
    PENDING_ACTIVATION = 2
}

export enum OpenRequest {
    NO = 0,
    YES = 1
}

export interface InvestorsFetchRequest {
    size: number;
    page: number;
    sort?: string;
}

export interface GetUsersResponse {
    data: Investor[],
    count: number
}