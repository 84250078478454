import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { BalanceBreakdown } from "../../../../../api/requests/getUserBalanceBreakdowns/types";
import { changeBalanceBreakdown } from "../../../../../api/requests/changeBalanceBreakdown/ChangeBalanceBreakdown";
import { ChangeBalanceBreakdownParams } from "../../../../../api/requests/changeBalanceBreakdown/types";
import { ChangeBalanceBreakdownProps as ChangeBalanceBreakdownApiProps } from "../../../../../api/requests/changeBalanceBreakdown/ChangeBalanceBreakdown";
import { MAX_NUMBER } from "../../../../../helpers/helpers";
import styles from './ChangeBalanceBreakdown.module.scss';

interface ChangeBalanceBreakdownProps {
    notification: React.RefObject<Toast>,
    setVisible: Dispatch<SetStateAction<boolean>>,
    balanceBreakdown: BalanceBreakdown,
    refetch: () => void,
}

export const ChangeBalanceBreakdown = ({ notification, setVisible, balanceBreakdown, refetch }: ChangeBalanceBreakdownProps) => {
    const { t } = useTranslation();

    const { values, touched, errors, dirty, setFieldValue, submitForm } = useFormik<BalanceBreakdown>({
        initialValues: balanceBreakdown,
        validate: (data: BalanceBreakdown) => {
            let errors: any = {};

            if (!data.balance) {
                errors.balance = t('user.modals.changeBalanceBreakdown.errors.balanceMissing');
            }
            if (!data.interest) {
                errors.interest = t('user.modals.changeBalanceBreakdown.errors.interestMissing');
            }
            if (!data.total) {
                errors.total = t('user.modals.changeBalanceBreakdown.errors.totalMissing');
            }

            return errors;
        },
        onSubmit: async (data: BalanceBreakdown) => {
            try {
                const newData: ChangeBalanceBreakdownParams = {
                    balance: data.balance,
                    interest: data.interest,
                    total: data.total,
                };
                const params: ChangeBalanceBreakdownApiProps = {
                    notification,
                    balanceBreakdownId: data.id,
                    params: newData
                };
                await changeBalanceBreakdown(params);
                refetch();
                setVisible(false);
            } catch (error) {
                console.warn(error);
            }
        }
    });

    const isFormFieldInvalid = (name: string) => !!((touched as { [key: string]: boolean })[name] && (errors as { [key: string]: boolean })[name]);

    const getFormErrorMessage = (name: string) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{(errors as { [key: string]: boolean })[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submitForm();
    };

    const handleBlur = useCallback(() => {
        if (values.balance > MAX_NUMBER) {
            setFieldValue('balance', MAX_NUMBER);
        }
        if (values.interest > MAX_NUMBER) {
            setFieldValue('interest', MAX_NUMBER);
        }
        if (values.total > MAX_NUMBER) {
            setFieldValue('total', MAX_NUMBER);
        }
    }, [setFieldValue, values.balance, values.interest, values.total]);

    useEffect(() => {
        handleBlur();
    }, [handleBlur]);

    return (
        <form className={styles.formWrapper} onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event)}>
            <div className="mb-5">
                <span className="p-float-label">
                    <InputNumber
                        className={styles.customInput}
                        id="balance"
                        max={MAX_NUMBER}
                        maxFractionDigits={2}
                        value={values.balance}
                        onChange={(e: InputNumberChangeEvent) => setFieldValue('balance', e.value)}
                        onBlur={handleBlur}
                    />
                    <label htmlFor="balance">{t('user.modals.changeBalanceBreakdown.balance')}</label>
                </span>
                {getFormErrorMessage('balance')}
            </div>
            <div className="mb-5">
                <span className="p-float-label">
                    <InputNumber
                        className={styles.customInput}
                        id="interest"
                        max={MAX_NUMBER}
                        maxFractionDigits={2}
                        value={values.interest}
                        onChange={(e: InputNumberChangeEvent) => setFieldValue('interest', e.value)}
                        onBlur={handleBlur}
                    />
                    <label htmlFor="interest">{t('user.modals.changeBalanceBreakdown.interest')}</label>
                </span>
                {getFormErrorMessage('interest')}
            </div>
            <div className="mb-5">
                <span className="p-float-label">
                    <InputNumber
                        className={styles.customInput}
                        id="total"
                        max={MAX_NUMBER}
                        maxFractionDigits={2}
                        value={values.total}
                        onChange={(e: InputNumberChangeEvent) => setFieldValue('total', e.value)}
                        onBlur={handleBlur}
                    />
                    <label htmlFor="total">{t('user.modals.changeBalanceBreakdown.total')}</label>
                </span>
                {getFormErrorMessage('total')}
            </div>
            <div className={styles.buttonsWrapper}>
                <Button type="button" label={t('user.modals.cancel')} onClick={() => setVisible(false)} text />
                <Button type="submit" disabled={!dirty} label={t('user.modals.save')} />
            </div>
        </form>
    );
};