import { useEffect, useState } from "react";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  const [errorMsg, setErrorMsg] = useState<string>('');
  useEffect(() => {
    if (isRouteErrorResponse(error))
      setErrorMsg(error.statusText);
  }, [error]);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorMsg}</i>
      </p>
    </div>
  );
}