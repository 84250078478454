import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styles from './Root.module.scss';
import { Toast } from "primereact/toast";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { Footer } from "../../components/footer/Footer";
import { isTokenValid } from "../../helpers/helpers";
import { refreshToken } from "../../api/requests/refreshToken/RefreshToken";
import globalRouter from "../../globalRouter";

interface RootProps {
    notification: React.RefObject<Toast>
}

export const Root = ({ notification }: RootProps) => {
    const [isUserLogedIn, setIsUserLogedIn] = useState<boolean | null>(null);
    const navigate = useNavigate();
    globalRouter.navigate = navigate;

    const checkUser = async () => {
        const smartCubeAccessToken = localStorage.getItem('smartCube_access_token');
        const smartCubeRefreshToken = localStorage.getItem('smartCube_refresh_token');
        const isAccessTokenValid = isTokenValid(smartCubeAccessToken);
        const isRefreshTokenValid = isTokenValid(smartCubeRefreshToken);

        if (isAccessTokenValid) {
            setIsUserLogedIn(true);
        } else if (isRefreshTokenValid) {
            try {
                const res = await refreshToken({ refreshTokenValue: smartCubeRefreshToken! });
                if (res) {
                    localStorage.setItem('smartCube_access_token', res.access_token);
                    localStorage.setItem('smartCube_refresh_token', res.refresh_token);
                    setIsUserLogedIn(true);
                } else {
                    redirectToLogin();
                }
            } catch (error) {
                console.warn(error);
                redirectToLogin();
            }
        } else {
            redirectToLogin();
        }
    };

    const redirectToLogin = () => {
        setIsUserLogedIn(false);
        navigate('/login');
    };

    useEffect(() => {
        checkUser();
    });

    return (
        <div className={styles.rootWrapper}>
            <Sidebar />
            <div className={styles.outletContentWrapper}>
                <div className={styles.innerContentWrapper}>
                    {isUserLogedIn && <Outlet />}
                </div>
                <div className={styles.footerWrapper}>
                    <Footer />
                </div>
            </div>
        </div>
    );
};