import styles from './Login.module.scss';
import { InputText } from 'primereact/inputtext';
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { LoginRequestParams } from '../../api/requests/login/types';
import { login } from '../../api/requests/login/Login';
import { useNavigate } from 'react-router';
import { isTokenValid } from '../../helpers/helpers';
import { refreshToken } from '../../api/requests/refreshToken/RefreshToken';
import globalRouter from '../../globalRouter';

interface LoginProps {
    notification: React.RefObject<Toast>
}

export const Login = ({ notification }: LoginProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    globalRouter.navigate = navigate;

    const checkUser = async () => {
        const smartCubeAccessToken = localStorage.getItem('smartCube_access_token');
        const smartCubeRefreshToken = localStorage.getItem('smartCube_refresh_token');
        const isAccessTokenValid = isTokenValid(smartCubeAccessToken);
        const isRefreshTokenValid = isTokenValid(smartCubeRefreshToken);

        if (isAccessTokenValid) {
            navigate('/', { replace: true });
        } else if (isRefreshTokenValid) {
            try {
                const res = await refreshToken({ refreshTokenValue: smartCubeRefreshToken! });
                if (res) {
                    localStorage.setItem('smartCube_access_token', res.access_token);
                    localStorage.setItem('smartCube_refresh_token', res.refresh_token);
                    navigate('/', { replace: true });
                }
            } catch (error) {
                console.warn(error);
            }
        }
    };

    useEffect(() => {
        checkUser();
    });

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
    };

    const formik = useFormik<LoginRequestParams>({
        initialValues: {
            mail: '',
            password: ''
        },
        validate: (data: LoginRequestParams) => {
            let errors: any = {};

            if (!data.mail) {
                errors.mail = t('login.errors.mailMissing');
            } else if (!isValidEmail(data.mail)) {
                errors.mail = t('login.errors.invalidEmail');
            }
            if (!data.password) {
                errors.password = t('login.errors.passwordMissing');
            }

            return errors;
        },
        onSubmit: async (data: LoginRequestParams) => {
            try {
                const tokens = await login({ params: data, notification });
                if (tokens) {
                    localStorage.setItem('smartCube_access_token', tokens.access_token);
                    localStorage.setItem('smartCube_refresh_token', tokens.refresh_token);
                    navigate('/', { replace: true });
                }
            } catch (error) {
                // handle error here if needed
                console.warn(error);
            }
        }
    });

    const isFormFieldInvalid = (name: string) => !!((formik.touched as { [key: string]: boolean })[name] && (formik.errors as { [key: string]: boolean })[name]);

    const getFormErrorMessage = (name: string) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{(formik.errors as { [key: string]: boolean })[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <form className={styles.loginWrapper} onSubmit={(event) => event.preventDefault()}>
            <div className={styles.logoWrapper}>
                <img src='/icon-192.png' alt='logo' />
                <h1>{t('appName')}</h1>
            </div>
            <div className={styles.loginForm}>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputText
                            id="mail"
                            value={formik.values.mail}
                            onChange={(e) => formik.setFieldValue('mail', e.target.value)}
                            className={classNames({ 'p-invalid': isFormFieldInvalid('mail') }, 'p-inputtext-lg')}
                        />
                        <label htmlFor="mail">{t('login.mail')}</label>
                    </span>
                    {getFormErrorMessage('mail')}
                </div>
                <div className='mb-5'>
                    <span className="p-float-label">
                        <Password
                            inputId="password"
                            value={formik.values.password}
                            onChange={(e) => formik.setFieldValue('password', e.target.value)} feedback={false}
                            className={classNames({ 'p-invalid': isFormFieldInvalid('password') }, 'p-inputtext-lg')}
                        />
                        <label htmlFor="password">{t('login.password')}</label>
                    </span>
                    {getFormErrorMessage('password')}
                </div>
                <Button onClick={formik.submitForm} size='large' label="Login" />
            </div>
        </form>
    );
};