import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { Toast } from 'primereact/toast';
import { handleAxiosError } from '../../../helpers/helpers';
import { AddCalculationParams } from './types';

interface AddCalculationProps {
    notification: React.RefObject<Toast>,
    userId: string,
    params: AddCalculationParams,
}

export const addCalculation = async ({ notification, userId, params }: AddCalculationProps) => {
    try {
        const res = await axiosInstance.put(`/user/calculate-daybreakdown/${userId}?endYear=${params.endYear}`);

        if (notification.current) {
            notification.current.show({
                severity: 'success',
                summary: i18n.t('user.success.addCalculation.summary'),
            });
        }
        return res;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('user.errors.addCalculation.summary'));

        throw error;
    }
};