import React, { useEffect, useCallback, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { UserRequest } from "../../../../../api/requests/getUserRequests/types";
import { ChangeRequestStatusProps, changeRequestFee } from "../../../../../api/requests/changeRequestFee/ChangeRequestFee";
import styles from './ChangeFee.module.scss';

interface ChangeFeeProps {
    notification: React.RefObject<Toast>,
    setVisible: Dispatch<SetStateAction<boolean>>,
    request: UserRequest,
    refetch: () => void,
}

export const ChangeFee = ({ notification, setVisible, request, refetch }: ChangeFeeProps) => {
    const { t } = useTranslation();
    const [gasFee, setGasFee] = useState<number>(request.gasFee);
    const [scFee, setScFee] = useState<number>(request.scFee);

    const handleBlur = useCallback(() => {
        if (gasFee > 100) {
            setGasFee(100);
        }
        if (scFee > 100) {
            setScFee(100);
        }
    }, [gasFee, setGasFee, scFee, setScFee]);

    const submitForm = async () => {
        try {
            const params: ChangeRequestStatusProps = {
                notification,
                requestId: request.id,
                params: {
                    gasFee,
                    scFee
                }
            };
            await changeRequestFee(params);
            refetch();
            setVisible(false);
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        handleBlur();
    }, [handleBlur]);

    return (
        <form className={styles.formWrapper} onSubmit={(event) => event.preventDefault()}>
            <div className="mb-5">
                <span className="p-float-label">
                    <InputNumber
                        className={styles.customInput}
                        id="gasFee"
                        prefix="%"
                        max={100}
                        min={0}
                        maxFractionDigits={2}
                        value={gasFee}
                        onChange={(e: InputNumberChangeEvent) => setGasFee(e.value as number)}
                        onBlur={handleBlur}
                    />
                    <label htmlFor="gasFee">{t('user.modals.changeRequestFee.gasFee')}</label>
                </span>
            </div>
            <div className="mb-5">
                <span className="p-float-label">
                    <InputNumber
                        className={styles.customInput}
                        id="SCFee"
                        prefix="%"
                        max={100}
                        min={0}
                        maxFractionDigits={2}
                        value={scFee}
                        onChange={(e: InputNumberChangeEvent) => setScFee(e.value as number)}
                        onBlur={handleBlur}
                    />
                    <label htmlFor="SCFee">{t('user.modals.changeRequestFee.SCFee')}</label>
                </span>
            </div>
            <div className={styles.buttonsWrapper}>
                <Button label={t('user.modals.cancel')} onClick={() => setVisible(false)} text />
                <Button onClick={submitForm} disabled={gasFee === request.gasFee && scFee === request.scFee} label={t('user.modals.save')} />
            </div>
        </form>
    );
};
