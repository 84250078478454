import { FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { getSystemSettings } from "../../api/requests/getSystemSettings/GetSystemSettings";
import { SystemSettings } from "../../api/requests/getSystemSettings/types";
import { changeSystemSettings } from "../../api/requests/changeSystemSettings/ChangeSystemSettings";
import styles from './Settings.module.scss';

interface SettingsProps {
    notification: React.RefObject<Toast>
}

export const Settings = ({ notification }: SettingsProps) => {
    const { t } = useTranslation();

    const { values, touched, errors, dirty, setFieldValue, resetForm, submitForm } = useFormik<SystemSettings>({
        initialValues: {
            walletAddress: '',
            warMsgAdd: '',
            warMsgWithdraw: '',
            warMsgClose: '',
        },
        validate: (data: SystemSettings) => {
            let errors: any = {};

            if (!data.walletAddress) {
                errors.walletAddress = t('systemSettings.errors.walletAddressMissing');
            }
            if (!data.warMsgAdd) {
                errors.warMsgAdd = t('systemSettings.errors.warMsgAddMissing');
            }
            if (!data.warMsgWithdraw) {
                errors.warMsgWithdraw = t('systemSettings.errors.warMsgWithdrawMissing');
            }
            if (!data.warMsgClose) {
                errors.warMsgClose = t('systemSettings.errors.warMsgCloseMissing');
            }

            return errors;
        },
        onSubmit: async (data: SystemSettings) => {
            try {
                const params = {
                    notification,
                    params: data
                };
                await changeSystemSettings(params);
                fetchSystemSettings();
            } catch (error) {
                console.warn(error);
            }
        }
    });

    const fetchSystemSettings = async () => {
        try {
            const res = await getSystemSettings({ notification });
            resetForm({
                values: res
            });
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        fetchSystemSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFormFieldInvalid = (name: string) => !!((touched as { [key: string]: boolean })[name] && (errors as { [key: string]: boolean })[name]);

    const getFormErrorMessage = (name: string) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{(errors as { [key: string]: boolean })[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const submitFormFunc = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submitForm();
    };

    return (
        <div>
            <h1 className="mb-7">{t('systemSettings.title')}</h1>
            <form className={styles.formWrapper} onSubmit={(event: FormEvent<HTMLFormElement>) => submitFormFunc(event)}>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputText id="walletAddress" value={values.walletAddress} onChange={(e) => setFieldValue('walletAddress', e.target.value)} />
                        <label htmlFor="walletAddress">{t('systemSettings.walletAddress')}</label>
                    </span>
                    {getFormErrorMessage('walletAddress')}
                </div>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputTextarea autoResize id="warMsgAdd" value={values.warMsgAdd} onChange={(e) => setFieldValue('warMsgAdd', e.target.value)} />
                        <label htmlFor="warMsgAdd">{t('systemSettings.warMsgAdd')}</label>
                    </span>
                    {getFormErrorMessage('warMsgAdd')}
                </div>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputTextarea autoResize id="warMsgWithdraw" value={values.warMsgWithdraw} onChange={(e) => setFieldValue('warMsgWithdraw', e.target.value)} />
                        <label htmlFor="warMsgWithdraw">{t('systemSettings.warMsgWithdraw')}</label>
                    </span>
                    {getFormErrorMessage('warMsgWithdraw')}
                </div>
                <div className="mb-5">
                    <span className="p-float-label">
                        <InputTextarea autoResize id="warMsgClose" value={values.warMsgClose} onChange={(e) => setFieldValue('warMsgClose', e.target.value)} />
                        <label htmlFor="warMsgClose">{t('systemSettings.warMsgClose')}</label>
                    </span>
                    {getFormErrorMessage('warMsgClose')}
                </div>
                <div>
                    <Button type="submit" disabled={!dirty} label={t('systemSettings.change')} />
                </div>
            </form>
        </div>
    );
};