import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import { GetUsersResponse, InvestorsFetchRequest } from './types';
import i18n from '../../..';
import { handleAxiosError } from '../../../helpers/helpers';

interface GetUsersProps {
    notification: React.RefObject<Toast>
    params?: InvestorsFetchRequest,
}

// Use this mocked data until api is done, uncomment code below and add: return mockedInvestorsList
// const mockedInvestorsList: GetUsersResponse = {
//     data: [
//         {
//             "id": "1",
//             "name": "First",
//             "username": "Test username",
//             "phoneNumber": "065 555 333",
//             "balance": 10000,
//             "status": 1,
//             "openRequest": 1
//         },
//         {
//             "id": "2",
//             "name": "Second",
//             "username": "Username",
//             "phoneNumber": "065 555 333",
//             "balance": 10000,
//             "status": 2,
//             "openRequest": 1
//         },
//         {
//             "id": "2",
//             "name": "Second",
//             "username": "Username",
//             "phoneNumber": "065 555 333",
//             "balance": 10000,
//             "status": 0,
//             "openRequest": 1
//         }
//     ],
//     count: 12
// };

export const getUsers = async ({ notification, params }: GetUsersProps) => {
    try {
        const res = await axiosInstance.get<GetUsersResponse>('/users', { params });
        return res.data;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('users.errors.getUsers.summary'));

        throw error;
    }
};