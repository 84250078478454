import axios from 'axios';
import { LoginResponseData } from '../login/types';
import { apiVersion } from '../../../appVersion';


export interface RefreshSessionProp {
    refreshTokenValue: string;
};

/**
* Refreshes the user session using the provided refresh token.
* @param {RefreshSessionProp} - Object containing the refresh token.
* @returns {LoginResponseData | null} - Returns the refreshed session data or null if an error occurs.
*/

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}${apiVersion}`,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const refreshToken = async ({ refreshTokenValue }: RefreshSessionProp) => {
    try {
        const res = await instance.post<LoginResponseData>('/user/refresh', {}, {
            headers: {
                Authorization: `Bearer ${refreshTokenValue}`,
            }
        });
        return res.data;
    } catch (error) {
        console.warn(error);
        return null;
    }
};