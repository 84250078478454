import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { getUserBalanceBreakdowns } from "../../../../api/requests/getUserBalanceBreakdowns/GetUserBalanceBreakdowns";
import { BalanceBreakdown as BalanceBreakdownType, UserBalanceBreakdownsFetchParams } from "../../../../api/requests/getUserBalanceBreakdowns/types";
import { ChangeBalanceBreakdown } from "./modals/ChangeBalanceBreakdown";
import { DropdownAction, TableColumn } from './types';
import { formatDateFunc, formatDateToYYYYMMDD, formatDollar, formatPercent } from "../../../../helpers/helpers";
import styles from './BalanceBreakdown.module.scss';

interface BalanceBreakdownProps {
    notification: React.RefObject<Toast>
}

export const BalanceBreakdown = ({ notification }: BalanceBreakdownProps) => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const breakdownsPerPage = 5;
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalBreakdowns, setTotalBreakdowns] = useState<number>();
    const [fromDate, setFromDate] = useState<Nullable<Date>>(null);
    const [toDate, setToDate] = useState<Nullable<Date>>(null);
    const [changeBreakdownModalVisible, setChangeBreakdownModalVisible] = useState<boolean>(false);
    const [selectedBreakdown, setSelectedBreakdown] = useState<BalanceBreakdownType | null>(null);

    useEffect(() => {
        if (!changeBreakdownModalVisible) {
            setSelectedBreakdown(null);
        }
    }, [changeBreakdownModalVisible]);

    useEffect(() => {
        setPageNumber(0);
    }, [fromDate, toDate]);

    const fetchUsersBalanceBreakdowns = async () => {
        try {
            const params = {
                notification,
                userId,
                params: {
                    size: breakdownsPerPage,
                    page: pageNumber,
                    sort: 'id'
                } as UserBalanceBreakdownsFetchParams
            };
            if (fromDate && toDate) {
                params.params.dateFrom = formatDateToYYYYMMDD(fromDate);
                params.params.dateTo = formatDateToYYYYMMDD(toDate);
            }
            const res = await getUserBalanceBreakdowns(params);
            setTotalBreakdowns(res.count);

            return res.data;
        } catch (error) {
            console.warn(error);
            return [];
        }
    };

    const { data, isLoading, refetch } = useQuery(['usersBalanceBreakdowns', pageNumber, fromDate, toDate], fetchUsersBalanceBreakdowns);

    const formatDate = (balanceBreakdown: BalanceBreakdownType) => {
        return (
            <div>
                {formatDateFunc(balanceBreakdown.date)}
            </div>
        );
    };

    const getBalance = (balanceBreakdown: BalanceBreakdownType) => {
        return (
            <div>
                {formatDollar(balanceBreakdown.balance)}
            </div>
        );
    };

    const getTotal = (balanceBreakdown: BalanceBreakdownType) => {
        return (
            <div>
                {formatDollar(balanceBreakdown.total)}
            </div>
        );
    };

    const getInterest = (balanceBreakdown: BalanceBreakdownType) => {
        return (
            <div>
                {formatPercent(balanceBreakdown.interest)}
            </div>
        );
    };

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setPageNumber(event.page);
    };

    const tableData: TableColumn[] = [
        {
            field: "balance",
            header: t('user.balanceBreakdownsTable.balance'),
            body: getBalance
        },
        {
            field: "interest",
            header: t('user.balanceBreakdownsTable.interest'),
            body: getInterest
        },
        {
            field: "total",
            header: t('user.balanceBreakdownsTable.total'),
            body: getTotal
        },
        {
            field: "date",
            header: t('user.balanceBreakdownsTable.date'),
            body: formatDate
        }
    ];

    const getActions = (rowData: BalanceBreakdownType): DropdownAction[] => {
        let actions: DropdownAction[] = [
            {
                label: t('user.balanceBreakdownsTable.actions.change'),
                onClick: (rowData: BalanceBreakdownType) => {
                    setSelectedBreakdown(rowData);
                    setChangeBreakdownModalVisible(true);
                }
            },
        ];

        return actions;
    };

    const actionsTemplate = (rowData: BalanceBreakdownType) => {
        return (
            <div>
                <Dropdown className='actions-dropdown' onChange={(event: DropdownChangeEvent) => event.value.onClick(rowData)} dropdownIcon="pi pi-ellipsis-v" optionLabel="label" options={getActions(rowData)} />
            </div>
        );
    };

    return (
        <div className="mb-5">
            <h2>{t('user.balanceBreakdownsTable.title')}</h2>
            {
                isLoading ? (
                    <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                ) : (
                    <div>
                        <div className={styles.tableHeaderWrapper}>
                            <span>{t('user.balanceBreakdownsTable.from')}</span>
                            <Calendar value={fromDate} maxDate={toDate ? toDate : undefined} onChange={(e) => setFromDate(e.value as Date)} showIcon />
                            <span>{t('user.balanceBreakdownsTable.to')}</span>
                            <Calendar value={toDate} minDate={fromDate ? fromDate : undefined} onChange={(e) => setToDate(e.value as Date)} showIcon />
                        </div>
                        <DataTable value={data} >
                            {tableData.map((col) => (
                                <Column key={col.field} field={col.field} header={col.header} body={col.body} />
                            ))}
                            <Column
                                header={'Actions'}
                                align="left"
                                alignFrozen="right"
                                frozen
                                style={{ width: '80px', textAlign: 'right' }}
                                body={actionsTemplate}
                            />
                        </DataTable>
                        <Paginator first={pageNumber * breakdownsPerPage} rows={breakdownsPerPage} totalRecords={totalBreakdowns} onPageChange={onPageChange} />
                    </div>
                )
            }
            <Dialog header={t('user.modals.changeBalanceBreakdown.title', { date: selectedBreakdown && formatDateFunc(selectedBreakdown.date) })} visible={changeBreakdownModalVisible} onHide={() => setChangeBreakdownModalVisible(false)}>
                {selectedBreakdown && <ChangeBalanceBreakdown notification={notification} setVisible={setChangeBreakdownModalVisible} refetch={refetch} balanceBreakdown={selectedBreakdown!} />}
            </Dialog>
        </div>
    );
};