import React, { useRef } from 'react';
import styles from './App.module.scss';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { PrimeReactProvider } from 'primereact/api';
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import { QueryClient, QueryClientProvider } from 'react-query';

import { Root } from './Pages/Root/Root';
import { Login } from './Pages/Login/Login';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import { Users } from './Pages/Users/Users';
import { Settings } from './Pages/Settings/Settings';
import { Profile } from './Pages/Profile/Profile';
import { User } from './Pages/User/User';

function App() {
  const queryClient = new QueryClient();
  const notification = useRef<Toast>(null);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root notification={notification} />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Users notification={notification} />,
        },
        {
          path: 'users',
          element: <Users notification={notification} />,
        },
        {
          path: 'users/:userId',
          element: <User notification={notification} />,
        },
        {
          path: 'settings',
          element: <Settings notification={notification} />,
        },
        {
          path: 'profile',
          element: <Profile notification={notification} />,
        }
      ]
    },
    {
      path: "/login",
      element: <Login notification={notification} />,
    }
  ]);

  return (
    <div className={styles.App}>
      <PrimeReactProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </PrimeReactProvider>
      <Toast ref={notification} />
    </div>
  );
}

export default App;
