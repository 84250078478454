import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { UserBalance } from "../../../../../api/requests/getUserBalance/types";
import { AddCalculationParams } from "../../../../../api/requests/addCalculation/types";
import { addCalculation } from "../../../../../api/requests/addCalculation/AddCalculation";
import styles from './AddCalculation.module.scss';

interface AddCalculationProps {
    notification: React.RefObject<Toast>,
    setVisible: Dispatch<SetStateAction<boolean>>,
    balance: UserBalance,
    refetch: () => void,
}

export const AddCalculation = ({ notification, setVisible, balance, refetch }: AddCalculationProps) => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const [selectedYear, setSelectedYear] = useState<number | null>(balance.lastDay ? new Date(balance.lastDay).getFullYear() : null);
    const [options, setOptions] = useState<number[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const populateOptions = useCallback(async () => {
        const firstYear = balance.lastDay ? new Date(balance.lastDay).getFullYear() : new Date().getFullYear();
        const years: number[] = [];
        for (let i = 0; i < 5; i++) {
            years.push(firstYear + i);
        }
        setOptions(years);
    }, [balance]);

    useEffect(() => {
        populateOptions();
    }, [balance, populateOptions]);

    const submitForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (userId && !isSubmitting) {
            setIsSubmitting(true);
            try {
                const params = {
                    notification,
                    userId,
                    params: {
                        endYear: selectedYear,
                    } as AddCalculationParams,
                };
                await addCalculation(params);
                refetch();
                setVisible(false);
            } catch (error) {
                console.warn(error);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <form className={styles.formWrapper} onSubmit={(event: FormEvent<HTMLFormElement>) => submitForm(event)}>
            <div className="mb-5">
                <span className="p-float-label">
                    <Dropdown inputId="addCalculationYear" value={selectedYear} onChange={(e) => setSelectedYear(e.value)} options={options} className="w-20rem" />
                    <label htmlFor="addCalculationYear">{t('user.modals.addCalculation.yearTitle')}</label>
                </span>
            </div>
            <div className={styles.buttonsWrapper}>
                <Button type="button" label={t('user.modals.cancel')} onClick={() => setVisible(false)} text />
                <Button type="submit" label={t('user.modals.save')} disabled={!selectedYear || selectedYear === new Date(balance.lastDay).getFullYear() || isSubmitting} />
            </div>
        </form>
    );
};