import styles from './Footer.module.scss';
import { appVersion } from '../../appVersion';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footerSection}>
                <img src='/icon-192.png' alt='logo' />
                <div>{t('footer.version')} {appVersion}</div>
            </div>
            <div className={styles.footerSection}>
                {t('footer.copyright')}
            </div>
            <div className={styles.footerSection}>
                <div>{t('footer.poweredBy')}</div>
                {/* <img src="/SqaLogo192.png" alt="sqa logo" /> */}
            </div>
        </div>
    );
};