import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ChangeBalance } from "./modals/ChangeBalance";
import { AddCalculation } from "./modals/AddCalculation";
import { ChangeInterest } from "./modals/ChangeInterest";
import { UserBalance } from "../../../../api/requests/getUserBalance/types";
import { formatDateFunc, formatDollar, formatPercent } from "../../../../helpers/helpers";
import styles from './Details.module.scss';

interface DetailsProps {
    notification: React.RefObject<Toast>,
    refetch: () => void,
    balance?: UserBalance,
}

export const Details = ({ notification, refetch, balance }: DetailsProps) => {
    const { t } = useTranslation();
    const [changeBalanceModalVisible, setChangeBalanceModalVisible] = useState<boolean>(false);
    const [addCalculationModalVisible, setAddCalculationModalVisible] = useState<boolean>(false);
    const [changeInterestModalVisible, setChangeInterestModalVisible] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const refetchBalanceAndBreakdowns = () => {
        queryClient.invalidateQueries('usersBalanceBreakdowns');
        refetch();
    };

    return (
        <div className={styles.detailsWrapper}>
            <div className={styles.leftWrapper}>
                <div className={styles.column}>
                    <div className={styles.pair}>
                        <span className={styles.label}>
                            {t('user.details.balance')}
                        </span>
                        <span className={styles.value}>
                            {balance ? formatDollar(balance.balance) : '/'}
                        </span>
                    </div>
                    <div className={styles.pair}>
                        <span className={styles.label}>
                            {t('user.details.totalInvested')}
                        </span>
                        <span className={styles.value}>
                            {balance ? formatDollar(balance.totalInvested) : '/'}
                        </span>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.pair}>
                        <span className={styles.label}>
                            {t('user.details.amountEarned')}
                        </span>
                        <span className={styles.value}>
                            {balance ? formatDollar(balance.amountEarned) : '/'}
                        </span>
                    </div>
                    <div className={styles.pair}>
                        <span className={styles.label}>
                            {t('user.details.growth')}
                        </span>
                        <span className={styles.value}>
                            {balance ? formatPercent(balance.growth) : '/'}
                        </span>
                    </div>
                </div>
                {balance && <Button label={t('user.modals.change')} onClick={() => setChangeBalanceModalVisible(true)} />}
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.column}>
                    <div className={styles.pair}>
                        <span className={styles.label}>
                            {t('user.details.interest')}
                        </span>
                        <span className={styles.value}>
                            {balance ? `${balance.interest}%` : '/'}
                        </span>
                        {balance && <Button label={t('user.modals.change')} onClick={() => setChangeInterestModalVisible(true)} />}
                    </div>
                    <div className={styles.pair}>
                        <span className={styles.label}>
                            {t('user.details.lastDay')}
                        </span>
                        <span className={styles.value}>
                            {balance?.lastDay ? formatDateFunc(balance.lastDay) : '/'}
                        </span>
                        {balance && <Button label={t('user.modals.change')} onClick={() => setAddCalculationModalVisible(true)} />}
                    </div>
                </div>
            </div>
            <Dialog header={t('user.modals.changeBalance.title')} visible={changeBalanceModalVisible} onHide={() => setChangeBalanceModalVisible(false)}>
                <ChangeBalance notification={notification} setVisible={setChangeBalanceModalVisible} balance={balance!} refetch={refetch} />
            </Dialog>
            <Dialog header={t('user.modals.addCalculation.title')} visible={addCalculationModalVisible} onHide={() => setAddCalculationModalVisible(false)}>
                <AddCalculation notification={notification} setVisible={setAddCalculationModalVisible} balance={balance!} refetch={refetchBalanceAndBreakdowns} />
            </Dialog>
            <Dialog header={t('user.modals.changeInterest.title')} visible={changeInterestModalVisible} onHide={() => setChangeInterestModalVisible(false)}>
                <ChangeInterest notification={notification} setVisible={setChangeInterestModalVisible} balance={balance!} refetch={refetchBalanceAndBreakdowns} />
            </Dialog>
        </div>
    );
};