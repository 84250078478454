import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import { UserRequestsFetchParams, UserRequestsResponse } from './types';
import i18n from '../../..';
import { handleAxiosError } from '../../../helpers/helpers';

interface GetUserRequestsProps {
    notification: React.RefObject<Toast>,
    userId?: string,
    params?: UserRequestsFetchParams,
}

// Use this mocked data until api is done, uncomment code below and add: return mockedUserRequests
// const mockedUserRequests: UserRequestsResponse = {
//     data: [
//         {
//             id: "id",
//             type: 1,
//             amount: 100,
//             gasFee: 200,
//             scFee: 150,
//             walletAddress: "0xasdasdasd",
//             status: 1,
//             date: new Date()
//         }
//     ],
//     count: 1
// };

export const getUserRequests = async ({ notification, userId, params }: GetUserRequestsProps) => {
    try {
        const res = await axiosInstance.get<UserRequestsResponse>(`/user/balance/requests/${userId}`, { params });
        return res.data;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('user.errors.getUserRequests.summary'));

        throw error;
    }
};