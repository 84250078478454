import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import { UserBalanceBreakdownsFetchParams, UserBalanceBreakdownsResponse } from './types';
import i18n from '../../..';
import { handleAxiosError } from '../../../helpers/helpers';

interface GetUserBalanceBreakdownsProps {
    notification: React.RefObject<Toast>,
    userId?: string,
    params?: UserBalanceBreakdownsFetchParams,
}

// Use this mocked data until api is done, uncomment code below and add: return mockedUserBalanceBreakdowns
// const mockedUserBalanceBreakdowns: UserBalanceBreakdownsResponse = {
//     data: [
//         {
//             id: "1",
//             balance: 10000,
//             interest: 1000,
//             total: 11000,
//             date: new Date()
//         }
//     ],
//     count: 1
// };

export const getUserBalanceBreakdowns = async ({ notification, userId, params }: GetUserBalanceBreakdownsProps) => {
    try {
        const res = await axiosInstance.get<UserBalanceBreakdownsResponse>(`/user/balance/breakdown/${userId}`, { params });
        return res.data;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('user.errors.getUserBalanceBreakdowns.summary'));

        throw error;
    }
};