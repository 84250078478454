import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { Toast } from "primereact/toast";
import { UserInfo } from "./types";
import { handleAxiosError } from "../../../helpers/helpers";

interface GetUserInfoProps {
    notification: React.RefObject<Toast>,
}

export const getUserInfo = async ({ notification }: GetUserInfoProps) => {
    try {
        const res = await axiosInstance.get<UserInfo>(`/user`);
        return res.data;
    } catch (error) {
        handleAxiosError(error, notification, i18n.t('user.errors.getUserInfo.summary'));

        throw error;
    }
};