import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { UserBalance } from "../../../../../api/requests/getUserBalance/types";
import { ChangeInterestParams } from "../../../../../api/requests/changeInterest/types";
import { changeInterest } from "../../../../../api/requests/changeInterest/ChangeInterest";
import { MAX_NUMBER, formatDateToYYYYMMDD } from "../../../../../helpers/helpers";
import styles from './ChangeInterest.module.scss';

interface ChangeInterestProps {
    notification: React.RefObject<Toast>,
    setVisible: Dispatch<SetStateAction<boolean>>,
    balance: UserBalance,
    refetch: () => void,
}

export const ChangeInterest = ({ notification, setVisible, balance, refetch }: ChangeInterestProps) => {
    const { t } = useTranslation();
    const [customToDate, setCustomToDate] = useState<boolean>(true);
    const { userId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const today = new Date();

    const formik = useFormik<ChangeInterestParams>({
        initialValues: {
            interest: balance.interest,
            from: '',
            to: '',
        },
        validate: (data: ChangeInterestParams) => {
            let errors: any = {};

            if (data.interest === null || data.interest === undefined) {
                errors.interest = t('user.modals.changeInterest.errors.interestMissing');
            }
            if (!data.from) {
                errors.from = t('user.modals.changeInterest.errors.fromMissing');
            }
            if (customToDate && !data.to) {
                errors.to = t('user.modals.changeInterest.errors.toMissing');
            }

            return errors;
        },
        onSubmit: async (data: ChangeInterestParams) => {
            const fixedDate: ChangeInterestParams = {
                from: formatDateToYYYYMMDD(new Date(data.from)),
                to: formatDateToYYYYMMDD(new Date(data.to)),
                interest: data.interest,
            };
            if (userId && !isSubmitting) {
                setIsSubmitting(true);
                try {
                    const params = {
                        notification,
                        userId,
                        params: fixedDate,
                    };
                    await changeInterest(params);
                    refetch();
                    setVisible(false);
                } catch (error) {
                    console.warn(error);
                } finally {
                    setIsSubmitting(false);
                }
            }
        }
    });

    useEffect(() => {
        if (!customToDate) {
            formik.setFieldValue('to', new Date(balance.lastDay));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customToDate]);

    const isFormFieldInvalid = (name: string) => !!((formik.touched as { [key: string]: boolean })[name] && (formik.errors as { [key: string]: boolean })[name]);

    const getFormErrorMessage = (name: string) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{(formik.errors as { [key: string]: boolean })[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.submitForm();
    };

    const handleBlur = useCallback(() => {
        if (formik.values.interest > MAX_NUMBER) {
            formik.setFieldValue('interest', MAX_NUMBER);
        }
    }, [formik]);

    useEffect(() => {
        handleBlur();
    }, [handleBlur]);

    const handleCalendarChange = (e: CalendarChangeEvent, field: string) => {
        if (e.value) {
            formik.setFieldValue(field, e.value);
        }
    };

    return (
        <form className={styles.formWrapper} onSubmit={(event: FormEvent<HTMLFormElement>) => submit(event)}>
            <div className="mb-3">
                <span className="p-float-label">
                    <InputNumber
                        className={styles.input}
                        inputId="interest"
                        value={formik.values.interest}
                        onChange={(e: InputNumberChangeEvent) => formik.setFieldValue('interest', e.value)}
                        prefix="%"
                        max={MAX_NUMBER}
                        onBlur={handleBlur}
                        maxFractionDigits={2}
                    />
                    <label htmlFor="interest">{t('user.modals.changeInterest.interest')}</label>
                </span>
                {getFormErrorMessage('interest')}
            </div>
            <div className="mb-2">
                <span className="p-float-label">
                    <Calendar
                        id="from"
                        className={styles.input}
                        minDate={today}
                        maxDate={formik.values.to ? new Date(formik.values.to) : undefined}
                        value={formik.values.from}
                        onChange={(e: CalendarChangeEvent) => handleCalendarChange(e, 'from')} showIcon
                    />
                    <label htmlFor="from">{t('user.modals.changeInterest.from')}</label>
                </span>
                {getFormErrorMessage('from')}
            </div>
            <div className={`${styles.alignLeft} mb-2`}>
                <div className="">
                    <RadioButton
                        disabled={!balance.lastDay}
                        inputId="toLastDay"
                        name="toOptions"
                        value={false}
                        onChange={(e: RadioButtonChangeEvent) => setCustomToDate(e.value)}
                        checked={!customToDate}
                    />
                    <label htmlFor="toLastDay" className="ml-2">{t('user.modals.changeInterest.toLastDay')}</label>
                </div>
            </div>
            <div className={`${styles.alignLeft} mb-5`}>
                <div className={styles.radioWrapper}>
                    <RadioButton inputId="customTo" name="toOptions" value={true} onChange={(e: RadioButtonChangeEvent) => setCustomToDate(e.value)} checked={customToDate} />
                    <label htmlFor="customTo">{t('user.modals.changeInterest.customTo')}</label>
                    <div className={styles.toWrapper}>
                        <Calendar
                            className={styles.input}
                            minDate={formik.values.from ? new Date(formik.values.from) : undefined}
                            value={customToDate ? formik.values.to : new Date(balance.lastDay)}
                            disabled={!customToDate}
                            onChange={(e: CalendarChangeEvent) => handleCalendarChange(e, 'to')}
                            showIcon
                            placeholder={t('user.modals.changeInterest.to')}
                        />
                        {getFormErrorMessage('to')}
                    </div>
                </div>
            </div>
            <div className={styles.buttonsWrapper}>
                <Button type="button" label={t('user.modals.cancel')} onClick={() => setVisible(false)} text />
                <Button type="submit" label={t('user.modals.save')} disabled={isSubmitting} />
            </div>
        </form>
    );
};