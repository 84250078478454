import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { getUsers } from '../../api/requests/getUsers/GetUsers';
import { exportUsers } from '../../api/requests/exportUsers/ExportUsers';
import { activateUser } from '../../api/requests/activateUser/ActivateUser';
import { closeUser } from '../../api/requests/closeUser/CloseUser';
import { Investor, Status } from '../../api/requests/getUsers/types';
import { DropdownAction, TableColumn } from './types';
import { debounce } from "ts-debounce";
import { formatDollar } from '../../helpers/helpers';

interface UsersProps {
    notification: React.RefObject<Toast>
}

export const Users = ({ notification }: UsersProps) => {
    const { t } = useTranslation();
    const investorsPerPage = 10;
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalInvestors, setTotalInvestors] = useState<number>();
    const navigate = useNavigate();

    const fetchInvestors = async () => {
        try {
            const params = {
                notification,
                params: {
                    size: investorsPerPage,
                    page: pageNumber,
                    sort: 'id'
                }
            };
            const res = await getUsers(params);
            setTotalInvestors(res.count);
            return res.data;
        } catch (error) {
            console.warn(error);
            return [];
        }
    };

    const { data, isLoading, refetch } = useQuery(['investors', pageNumber], fetchInvestors);

    const getStatus = (investor: Investor) => {
        return (
            <div>
                {t(`users.table.status.${investor.status}`)}
            </div>
        );
    };

    const getOpenRequest = (investor: Investor) => {
        return (
            <div>
                {t(`users.table.openRequest.${investor.openRequest}`)}
            </div>
        );
    };

    const getBalance = (investor: Investor) => {
        return (
            <div>
                {formatDollar(investor.balance)}
            </div>
        );
    };

    const tableData: TableColumn[] = [
        {
            field: "name",
            header: t('users.table.name')
        },
        {
            field: "username",
            header: t('users.table.username')
        },
        {
            field: "phoneNumber",
            header: t('users.table.phoneNumber')
        },
        {
            field: "balance",
            header: t('users.table.balance'),
            body: getBalance
        },
        {
            field: "status",
            header: t('users.table.statusTitle'),
            body: getStatus
        },
        {
            field: "openRequest",
            header: t('users.table.openRequestTitle'),
            body: getOpenRequest
        },
    ];

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setPageNumber(event.page);
    };

    const getActions = (rowData: Investor): DropdownAction[] => {
        let actions: DropdownAction[] = [
            {
                label: t('users.actions.details'),
                onClick: (rowData: Investor) => {
                    navigate(`/users/${rowData.id}`);
                }
            },
        ];

        if (rowData.status === Status.ACTIVE) {
            actions.push(
                {
                    label: t('users.actions.close'),
                    onClick: (rowData: Investor) => {
                        confirmDialog({
                            message: t('users.dialog.confirmClose', { name: rowData.name }),
                            header: t('users.dialog.confirmationRequired'),
                            accept: async () => {
                                try {
                                    await closeUser({ notification, userId: rowData.id });
                                } catch (error) {
                                    console.warn(error);
                                } finally {
                                    refetch();
                                }
                            },
                        });
                    }
                }
            );
        } else if (rowData.status === Status.CLOSED || rowData.status === Status.PENDING_ACTIVATION) {
            actions.push(
                {
                    label: t('users.actions.activate'),
                    onClick: (rowData: Investor) => {
                        confirmDialog({
                            message: t('users.dialog.confirmActivation', { name: rowData.name }),
                            header: t('users.dialog.confirmationRequired'),
                            accept: async () => {
                                try {
                                    await activateUser({ notification, userId: rowData.id });
                                } catch (error) {
                                    console.warn(error);
                                } finally {
                                    refetch();
                                }
                            },
                        });
                    }
                }
            );
        }

        return actions;
    };

    const actionsTemplate = (rowData: Investor) => {
        return (
            <div>
                <Dropdown className='actions-dropdown' onChange={(event: DropdownChangeEvent) => event.value.onClick(rowData)} dropdownIcon="pi pi-ellipsis-v" optionLabel="label" options={getActions(rowData)} />
            </div>
        );
    };

    const handleExport = async () => {
        try {
            const res = await exportUsers({ notification });
            const blob = new Blob([res.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `smartCubeUsers.xlsx`;
            link.click();

            setTimeout(() => {
                link.remove();
                window.URL.revokeObjectURL(link.href);
            }, 1000);
        } catch (error) {
            console.warn(error);
        }
    };

    const debouncedExport = debounce(handleExport, 1000, { isImmediate: true });

    return (
        <div>
            <h1 className="mb-5">{t('users.title')}</h1>
            <Button className="mb-5" label={t('users.export')} onClick={() => debouncedExport()} />
            {
                isLoading ? (
                    <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                ) : (
                    <div>
                        <DataTable value={data} >
                            {tableData.map((col) => (
                                <Column key={col.field} field={col.field} header={col.header} body={col.body} />
                            ))}
                            <Column
                                header={'Actions'}
                                align="left"
                                alignFrozen="right"
                                frozen
                                style={{ width: '80px', textAlign: 'right' }}
                                body={actionsTemplate}
                            />
                        </DataTable>
                        <Paginator first={pageNumber * investorsPerPage} rows={investorsPerPage} totalRecords={totalInvestors} onPageChange={onPageChange} />
                    </div>
                )
            }
            <ConfirmDialog />
        </div>
    );
};