import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserBalance } from '../../api/requests/getUserBalance/types';
import { getUserBalance } from '../../api/requests/getUserBalance/GetUserBalance';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import styles from './User.module.scss';
import { Details } from './components/details/Details';
import { Requests } from './components/requests/Requests';
import { BalanceBreakdown } from './components/balanceBreakdown/BalanceBreakdown';

interface UserProps {
    notification: React.RefObject<Toast>
}

export const User = ({ notification }: UserProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userId } = useParams();
    const [balance, setBalance] = useState<UserBalance>();

    const fetchUserBalance = useCallback(async () => {
        if (userId) {
            try {
                const res = await getUserBalance({ notification, userId });
                setBalance(res);
            } catch (error) {
                console.warn(error);
            }
        }
    }, [notification, userId]);

    useEffect(() => {
        fetchUserBalance();
    }, [fetchUserBalance]);

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <div className={styles.headerWrapper}>
                <Button onClick={handleGoBack} icon="pi pi-arrow-left" rounded text aria-label="Navigate back" />
                <h1>{t('user.title')} {balance?.name ? balance.name : t('user.unknown')}</h1>
            </div>
            <Details notification={notification} balance={balance} refetch={fetchUserBalance} />
            <Requests notification={notification} fetchUserBalance={fetchUserBalance} />
            <BalanceBreakdown notification={notification} />
        </div>
    );
};