import { Link } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Sidebar = () => {
    const location = useLocation();
    const isActiveRoute = (route: string) => {
        return route === location.pathname;
    };
    const { t } = useTranslation();

    return (
        <div className={styles.sidebarWrapper}>
            <div className={styles.imageWrapper}>
                <img src='/icon-192.png' alt='logo' />
                <h1>{t('appName')}</h1>
            </div>
            <div className={styles.linksWrapper}>
                <Link to="/users" className={`${(isActiveRoute('/users') || isActiveRoute('/')) && styles.active}`}>
                    <i className="pi pi-users" />
                    <div className={styles.linkText}>{t('sidebar.links.users')}</div>
                </ Link>
                <Link to="/settings" className={`${isActiveRoute('/settings') && styles.active}`}>
                    <i className="pi pi-cog" />
                    <div className={styles.linkText}>{t('sidebar.links.systemSettings')}</div>
                </ Link>
                <Link to="/profile" className={`${isActiveRoute('/profile') && styles.active}`}>
                    <i className="pi pi-id-card" />
                    <div className={styles.linkText}>{t('sidebar.links.myProfile')}</div>
                </ Link>
            </div>
        </div>
    );
};