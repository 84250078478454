import { Toast } from 'primereact/toast';
import axiosInstance from '../../axios-config';
import i18n from '../../..';
import { handleAxiosError } from '../../../helpers/helpers';
import { UserBalance } from './types';

interface GetUserBalanceProps {
    notification: React.RefObject<Toast>
    userId: string,
}

// Use this mocked data until api is done, uncomment code below and add: return mockedUserBalance
// const mockedUserBalance: UserBalance =
// {
//     id: 'test',
//     name: 'test',
//     balance: 11000,
//     totalInvested: 10000,
//     growth: 1.1,
//     amountEarned: 1000,
//     interest: 10,
//     lastDay: new Date()
// };

export const getUserBalance = async ({ notification, userId }: GetUserBalanceProps) => {
    try {
        const res = await axiosInstance.get<UserBalance>(`/user/balance/${userId}`);
        return res.data;
    } catch (error: Error | any) {
        handleAxiosError(error, notification, i18n.t('user.errors.getUserBalance.summary'));

        throw error;
    }
};